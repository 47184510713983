@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

@layer base {
 
  .border{
    border: 1px solid rgb(207, 205, 205);
  }
  body {
    @apply font-sans text-golden-text bg-golden-light;
  }
}
.card {
  @apply bg-white rounded-lg shadow-card p-4;
}

.overtop{
  position:relative;
  top: -8px;
  left: 3px;
}
@layer components {
  .btn {
    @apply px-4 py-2 rounded-lg font-medium transition duration-150 ease-in-out;
  }

  .btn-primary {
    @apply bg-golden-navy text-white hover:bg-opacity-90;
  }

  .btn-secondary {
    @apply bg-golden-yellow text-golden-navy hover:bg-opacity-90;
  }

  .card {
    @apply bg-white rounded-lg shadow-card p-4;
    border: 1px solid #e2e8f0;
  }

  .input {
    @apply w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-golden-blue focus:border-transparent;
  }

  .heading {
    @apply font-bold text-golden-navy;
  }

  .subheading {
    @apply  font-semibold text-golden-navy;
  }
  
}